$(document).ready(function(){
  $('.owl-partenaires').owlCarousel(
	  {
		  items: 10,
		  autoplay: true,
		  smartSpeed: 1000,
		  autoWidth: true,
		  autoplayHoverPause: false,
		  autoplayTimeout: 2000,
	  }
  );
});
